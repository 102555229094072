// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

@import "mixins";
@import "deactivate_select2";

/**
 * Navigation
 */
#primary-nav {
  ul {
    li.level-1 {
      white-space: nowrap;
      width: auto;
      padding: 1rem 0rem;
      min-width: 18%;
      font-size: 12.5px;
      @include breakpoint($md) {
        font-size: 15px;
      }
      @include breakpoint($lg) {
        font-size: 16px;
      }
      .active-trail {
        color: #B31B1B;
      }
    }
    li:nth-child(2),
    li:nth-child(3) {
      width: 23%;
    }
  }
}

.cycle-slideshow {
  .slide-text {
    p {
      @include breakpoint($md) {
        padding-top: 1.8rem;
      }
    }
    h2 {
      @include breakpoint($md) {
        padding: 18px 0 10px 10px;
      }
    }
  }
  .carousel-slide {
    .slide-title {
      a.external-link {
        &:after {
          line-height: 1.5em;
          font-size: 85%;
          color: #ddd;
        }
      }
    }
  }
}

.panel-pane {
  .caption-left,
  .caption-right {
    margin-top: 0.5rem !important;
  }
}

.panel-student-services-home {
  #student-services-home-header,
  #student-services-home-bottom {
    .container-liquid {
      .row {
        margin: 0 !important;
      }
    }
  }
  #student-services-home-content-header {
    .panel-pane {
      text-align: center;
      padding-top: 0.5rem;
      color: #555;
      div.floatme {
        @include breakpoint($sm) {
          float: left;
          width: 33.333333333%;
        }
        text-align: center;
        padding-top: 1rem;
        margin-bottom: 2rem;
        img {
          max-height: 16rem;
        }
        p {
          padding-top: 0.8rem;
          margin-bottom: 0;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 1.4rem;
          color: #555;
          border-top: 1px solid #eee;
          margin: 0.6rem 6rem 0.8rem 6rem;;
        }
        // &:not(:last-child) {
        //   border-right: 1px solid #eee;
        // }
      }
      .pane-title {
        font-size: 4rem;
      }
      clear {
        line-height: 1;
      }
    }
  }
  #student-services-home-top {
    float: none;
    clear: both;
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
    padding: 3rem 0;
    #top-2col-left {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: -2rem;
      @include breakpoint($md) {
        margin-bottom: 0;
      }
      div.panel-pane {
        margin: 0;
        color: #555;
        .pane-title {
          font-size: 2.2rem;
          margin: 0 0 1rem 0;
          @include breakpoint($lg) {
            margin: -1px 0 1rem 0;
            font-size: 2.4rem;
          }
        }
        img {
          @include breakpoint($md) {
            max-width: 79%;
          }
          @include breakpoint($lg) {
            max-width: 98%;
          }
        }
        p {
          padding-top: 1rem;
          margin: 0;
        }
        p:first-child {
          text-align: center;
        }
        > div {
          padding: 2rem;
          @include breakpoint($md) {
            padding: 44px 30px;
          }
          @include breakpoint($lg) {
            padding: 18px 30px;
          }
          background: #fff;
          &:hover {
            outline: 3px solid #efefef;
          }
        }
      }
    }
    #top-2col-right {
      padding-left: 10px;
      padding-right: 10px;
      div.panel-pane {
        margin: 0;
        color: #555;
        &:first-child {
          margin-top: 3.8rem;
          margin-bottom: 2rem;
          @include breakpoint($lg) {
            margin-top: 4rem;
          }
        }
        > div {
          padding: 2rem;
          @include breakpoint($sm) {
            padding: 1rem 3rem;
          }
          background: #fff;
          &:hover {
            outline: 3px solid #efefef;
          }
        }
        img {
          max-width: 98%;
        }
        p {
          padding-top: 1rem;
          margin: 0;
        }
      }
      // for admin page
      .panels-ipe-sort-container {
        div.panels-ipe-portlet-wrapper:nth-child(2) {
          margin-top: -2rem;
        }
      }
    }
  }

  #student-services-home-bottom {
    .panel-pane {
      position: relative;
      background: #ddd;
      width: 100%;
      height: auto;
      min-height: 45rem;
      z-index: 1;
      opacity: 1;
      overflow: hidden;
      background-image: url("/sites/default/files/styles/panopoly_image_original/public/student_life_bg.jpg");
      @include ss-cover-bg-image(50% 40%);
      padding-bottom: 2rem;
      color: #555;
      &:before {
       content: '';
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       z-index: 0;
       @include ss-linear-gradient-bg-image(top, bottom, $wcm-black, $wcm-black, 0.1, 0.1);
      }
      p {
        // color: #444;
        // text-shadow: 0px 0px #fff;
        color: #fff;
        text-shadow: 1px 1px 2px #000;
      }
      .fieldable-panels-pane {
        padding: 0 2rem;
        @include breakpoint($sm) {
          float: right;
          width: 50%;
          margin-right: 10%;
        }
        @include breakpoint($md) {
          width: 35%;
          margin-right: 12%;
        }
        h3 {
          font-size: 4rem;
          margin-top: 4rem;
        }
        .blue-link {
          margin-bottom: 0.6rem;
          max-width: 100%;
          @include opacity(0.84);
          a {
            //background-color: #00355F;
            background-color: $wcm-bright-orange;
            list-style-type: none;
            border: 0.3rem solid #fff;
            // font-style: italic;
            @include breakpoint($md) {
              border: 0.3rem solid #fff;
              max-width: 98%;
            }
            @include border-radius(1.2rem);
            color: #fff;
            text-align: center;
            display: block;
            padding: 0.6rem 0;
            &:hover {
              background-color: #b31b1b;
              text-decoration: none;
            }
            span {
              font-size: 1.9rem;
              font-style: normal;
            }
          }
        }
      }
    }
  }

  #student-services-home-footer {
    .container {
      padding: 0;
    }
    #footer-2col-left,
    #footer-2col-right {
      .view-display-id-panel_pane_1,
      .view-display-id-panel_pane_2 {
        .views-row {
          clear: both;
          .event-date {
            float: left;
            margin: 0.2rem 1rem 0.6rem 0;
            background: rgb(238, 238, 238) none repeat scroll 0% 0%;
            color: rgb(85, 85, 85);
            text-align: center;
            display: block;
            max-width: 7.4rem;
            padding: 0.4rem 0 0 0;
            border-color: #ddd;
            border-style: solid;
            border-width: 1px;
            @include border-radius(1px);
            width: 24%;
            @include breakpoint($sm) {
              width: 18%;
            }
            .date-display-single {
              display: block;
              font-family: "1898Sans-Regular",sans-serif;
              font-size: 1.9rem;
              line-height: 0.8;
              //text-transform: uppercase;
              margin-top: 1.2rem;
              margin-bottom: 0.7rem;
            }
            .date-display-range {
              display: block;
              font-family: "1898Sans-Regular",sans-serif;
              margin-top: 1.7rem;
              line-height: 0.8;
              letter-spacing: -0.05rem;
              margin-top: 1.4rem;
              margin-bottom: 1rem;
            }
            p {
              margin: 0;
            }
          }
          .event-title {
            font-size: 1.6rem;
            //font-weight: 800;
          }
        }
        .view-footer {
          clear: both;
          margin: 3.4rem 0 1rem 0;
          .btn {
            font-size: 1.3rem;
            @include breakpoint($sm) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    #footer-2col-left {
      .panel-pane {
        margin: 3rem 0;
        @include breakpoint($md) {
          padding-right: 3rem;
        }
        .pane-title {
          margin: 0 0 1rem 0;
        }
      }
      div[id^="edit-tid-"] {
        padding-top: 0.4rem;
        padding-top: 0.4rem;
        .select2,
        select {
          width: 25rem !important;
          @include breakpoint($sm) {
            width: 35rem !important;
          }
          @include breakpoint($md) {
            width: 40rem !important;
          }
          @include breakpoint($lg) {
            width: 45rem !important;
          }
        }
      }
    }
    #footer-2col-right {
      margin: 3rem 0;
      @include breakpoint($md) {
        border-left: 1px solid #ddd;
        padding-left: 3rem;
      }
      .panel-pane {
        .pane-title {
          margin: 0rem 0 1.2rem 0;
        }
      }
    }
  }
  form {
    .views-exposed-widget {
      .ajax-progress-throbber {
        margin-top: 1.2rem;
      }
    }
  }
}

.panel-hero-3col {
  #main-content {
    .panel-pane {
      table {
        tbody {
          border: 1px solid #ddd;
        }
        tr {
          border-bottom: 1px solid #ddd;
        }
        tr:nth-child(even) {
          background: #fefefe;
        }
        tr:nth-child(odd) {
          background: #efefef;
        }
        tr:first-child {
          background: #ccc;
        }
        td {
          padding: 0.4rem 0.6rem;
        }
        // td:nth-child(even) {
        //   background: #efefef;
        // }
        // td:nth-child(odd) {
        //   background: #fff;
        // }
      }
    }
    .ui-tabs {
      width: 100%;
      display: inline-block;
      font-family: "Open Sans",sans-serif;
      font-size: 15px;
      margin-top: -5px;
      .item-list > ul {
        padding: 0px;
        margin: 1rem 0;
        border-radius: 0px !important;
        border-bottom: 1px solid #ddd !important;
        &:after {
          display: block;
          clear: both;
          content: '';
        }
        li {
          float: left;
          list-style: outside none none;
          padding: 0px;
        }
        a {
          display: inline-block;
          border-radius: 5px 5px 0px 0px;
          color: #CF4520;
          border-color: #ddd !important;
          border-width: 1px 1px 0 1px !important;
          border-style: solid !important;
          font-size: 10px;
          padding: 5px 5px;
          @include transition(all linear 0.15s);
          @include breakpoint($sm) {
            font-size: 15px;
            padding: .5em 1em;
          }
          &:hover {
            border-color: #CF4520 !important;
            @include transition(all linear 0.25s);
            @include box-shadow(0, 1px, 4px, rgba(207, 69, 32, 1));
            text-decoration: none;
          }
        }
        li.ui-tabs-active {
          background: #fff;
          a {
            color: #fff;
            background: #CF4520;
          }
        }
        li.ui-state-default {
          background: #FFF;
        }
      }
      .ui-widget-header {
        background: #FFF !important;
        font-family: inherit !important;
        font-size: inherit !important;
      }
      .ui-widget-content a {
        color: #CF4520;
        &:hover {
          color: #E87722;
        }
      }
      .ui-tabs-panel {
        padding: 1.2rem 0 !important;
      }
      .view-content {
        .views-field {
          table {
            font-size: 1.2rem;
            @include breakpoint($lg) {
              font-size: 1.3rem;
            }
            tbody {
              border: 1px solid #ddd;
            }
            // tr {
            //   border-bottom: 1px solid #ddd;
            // }
            // tr:nth-child(even) {
            //   background: #fefefe;
            // }
            // tr:nth-child(odd) {
            //   background: #efefef;
            // }
            tr:first-child {
              //background: #ccc;
              background: #cfcfcf;
              td:not(:last-child) {
                border-right: 1px solid #eee;
              }
            }
            tr:not(:first-child) {
              td:nth-child(even) {
                background: #fff;
              }
              td:nth-child(odd) {
                background: #efefef;
              }
            }
            td {
              padding: 0.2rem 0.4rem;
              @include breakpoint($lg) {
                padding: 0.4rem 0.6rem;
              }
            }
          }
        }
      }
    }
  }
  .pane-events-panel-pane-4,
  .pane-events-panel-pane-5,
  .pane-events-panel-pane-6,
  .pane-events-panel-pane-7 {
    .views-row {
      margin-bottom: 1.5rem;
      .event-item {
        border-bottom: 1px solid #ddd;
        .event-date {
          font-size: 1.4rem;
        }
        .event-title {
          font-size: 1.6rem;
          font-weight: 800;
          & + div {
            line-height: 1;
          }
        }
      }
    }
  }
  .pane-events-panel-pane-4,
  .pane-events-panel-pane-5  {
    .select2 {
      width: 22rem !important;
      margin-bottom: 2rem;
    }
  }
  .pane-profiles-panel-pane-2 {
    .view-content {
      h3 {
        padding-bottom: 1rem;
      }
      div.views-row {
        border-bottom: 1px solid #efefef;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        display: inline-block;
        width: 100%;
        clear: both;
        .profile-list-image {
          @include breakpoint($md) {
            margin-right: 2rem;
            float: left;
          }
          .wcmc-profile-table {
            height: auto;
            width: auto;
            margin-bottom: 10px;
            max-height: 15rem;
            // min-height: 15rem;
          }
        }
        .views-field-nothing {
          .field-content {
            font-weight: 800;
          }
        }
      }
    }
  }
  .pane-news-highlights-panel-pane-1 {
    .view-content {
      div.views-row {
        border-bottom: 1px solid #efefef;
        padding-bottom: 1.8rem;
      }
    }
  }
  .pane-panopoly-taxonomy-taxonomy-content {
    .view-content {
      div.views-row {
        border-bottom: 1px solid #efefef;
      }
      article {
        h2 {
          font-size: 2.2rem;
        }
        ul {
          padding-top: 1rem;
        }
      }
    }
  }
  form {
    .views-exposed-widget {
      .ajax-progress-throbber {
        margin-top: 2rem;
      }
    }
  }
}

.information-column {
  .panel-pane {
    text-align: center;
    margin-bottom: 2rem;
    h3.pane-title {
      margin: 0;
      margin-bottom: 1.5rem;
    }
    .fieldable-panels-pane {
      ul {
        padding-left: 1.2rem;
        font-size: 1.4rem;
        text-align: left;
      }
    }
  }
  .wcmc-btn-link {
    text-align: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .pane-wcmc-contact-pane {
    .contact-info {
      h3.pane-title {
        margin: 0;
      }
    }
  }
  .pane-map-this-pane {
    //width: 255px;
    width: 100%;
    margin: 3rem auto 3rem auto;
    .map-this-location {
      text-align: center;
      a.external-link {
        // border-bottom: 1px solid #dddddd;
        &:after {
          display: none;
        }
        &:hover {
          color: #e87722;
          text-decoration: none;
        }
        .wcm-cta__map-this-sidebar {
          -webkit-transform: translate(0%, 0%);
          -ms-transform: translate(0%, 0%);
          -o-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
          .cta-icon {
            margin-left: -1.4rem !important;
            font-size: 3.8rem;
            color: #b31b1b;
            fill: #e97623;
          }
          > span {
            position: relative;
            bottom: 12px;
            left: 8px;
            color: #b31b1b;
            font-size: 1.5rem;
            // &:hover {
            //   color: #e87722;
            //   text-decoration: none;
            // }
          }
        }
      }
    }
  }
}

footer {
  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;
      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }
  .footer-site {
    .footer-address {
      border-right: none;
      @include breakpoint($md) {
        width: 20% !important;
      }
    }
    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;
      @include breakpoint($md) {
        width: 78% !important;
      }
      .block {
        @include ss-multi-column;
        @include ss-multi-gap;
        ul.menu {
          width: 85%;
          li:nth-child(2) {
            display: inline-block;
          }
        }
      }
    }
  }
}
