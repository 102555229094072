$prefixes: -webkit-, -moz-, -o-, "";

@mixin ss-multi-column($count: 3) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  -ms-column-count: $count;
  -o-column-count: $count;
  column-count: $count;
}

@mixin ss-multi-gap($width: 2rem) {
  -webkit-column-gap: $width;
  -moz-column-gap: $width;
  -ms-column-gap: $width;
  -o-column-gap: $width;
  column-gap: $width;
}

@mixin ss-cover-bg-image($x_pos: 50%, $y_pos: 0%) {
  background-repeat: no-repeat;
  background-position: $x_pos, $y_pos;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

@mixin ss-linear-gradient-bg-image($from_dir:top, $to_dir:bottom, $from_color:#000, $to_color:#000, $from_opacity:0.5, $to_opacity:0.5) {
  background-image: -moz-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* FF3.6+ */
  background-image: -webkit-gradient(linear, left $from_dir, left $to_dir, color-stop(0%, rgba($from_color, $from_opacity)), color-stop(100%, rgba($to_color, $to_opacity))); /* Chrome,Safari4+ */
  background-image: -webkit-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Chrome10+,Safari5.1+ */
  background-image: -o-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Opera 11.10+ */
  background-image: -ms-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* IE10+ */
  background-image: linear-gradient(to $to_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', GradientType='0'); /* IE6-9 */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', gradientType='0')";
}

@mixin border-radius($size...) {
  @if length($size) == 1 {
    @each $prefix in $prefixes {
      #{$prefix}border-radius: $size;
    }
  } @else {
    @include custom-border-radius($size...);
  }
}

@mixin custom-border-radius($top-l: 0, $top-r: 0, $bottom-r: 0, $bottom-l: 0) {
  @each $prefix in $prefixes {
    @if $prefix == "-moz-" {
      @if $top-l != 0 { -moz-border-radius-topleft: $top-l; }
      @if $top-r != 0 { -moz-border-radius-topright: $top-r; }
      @if $bottom-r != 0 { -moz-border-radius-bottomright: $bottom-r; }
      @if $bottom-l != 0 { -moz-border-radius-bottomleft: $bottom-l; }
    } @else {
      @if $top-l != 0 { #{$prefix}border-top-left-radius: $top-l; }
      @if $top-r != 0 { #{$prefix}border-top-right-radius: $top-r; }
      @if $bottom-r != 0 { #{$prefix}border-bottom-right-radius: $bottom-r; }
      @if $bottom-l != 0 { #{$prefix}border-bottom-left-radius: $bottom-l; }
    }
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
