/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/**
 * Navigation
 */
#primary-nav ul li.level-1 {
  white-space: nowrap;
  width: auto;
  padding: 1rem 0rem;
  min-width: 18%;
  font-size: 12.5px; }
  @media screen and (min-width: 992px) {
    #primary-nav ul li.level-1 {
      font-size: 15px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav ul li.level-1 {
      font-size: 16px; } }
  #primary-nav ul li.level-1 .active-trail {
    color: #B31B1B; }

#primary-nav ul li:nth-child(2),
#primary-nav ul li:nth-child(3) {
  width: 23%; }

@media screen and (min-width: 992px) {
  .cycle-slideshow .slide-text p {
    padding-top: 1.8rem; } }

@media screen and (min-width: 992px) {
  .cycle-slideshow .slide-text h2 {
    padding: 18px 0 10px 10px; } }

.cycle-slideshow .carousel-slide .slide-title a.external-link:after {
  line-height: 1.5em;
  font-size: 85%;
  color: #ddd; }

.panel-pane .caption-left,
.panel-pane .caption-right {
  margin-top: 0.5rem !important; }

.panel-student-services-home #student-services-home-header .container-liquid .row,
.panel-student-services-home #student-services-home-bottom .container-liquid .row {
  margin: 0 !important; }

.panel-student-services-home #student-services-home-content-header .panel-pane {
  text-align: center;
  padding-top: 0.5rem;
  color: #555; }
  .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme {
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme {
        float: left;
        width: 33.333333333%; } }
    .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme img {
      max-height: 16rem; }
    .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme p {
      padding-top: 0.8rem;
      margin-bottom: 0;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 1.4rem;
      color: #555;
      border-top: 1px solid #eee;
      margin: 0.6rem 6rem 0.8rem 6rem; }
  .panel-student-services-home #student-services-home-content-header .panel-pane .pane-title {
    font-size: 4rem; }
  .panel-student-services-home #student-services-home-content-header .panel-pane clear {
    line-height: 1; }

.panel-student-services-home #student-services-home-top {
  float: none;
  clear: both;
  background-color: #f8f8f8;
  border-top: 1px solid #eee;
  padding: 3rem 0; }
  .panel-student-services-home #student-services-home-top #top-2col-left {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: -2rem; }
    @media screen and (min-width: 992px) {
      .panel-student-services-home #student-services-home-top #top-2col-left {
        margin-bottom: 0; } }
    .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane {
      margin: 0;
      color: #555; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane .pane-title {
        font-size: 2.2rem;
        margin: 0 0 1rem 0; }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane .pane-title {
            margin: -1px 0 1rem 0;
            font-size: 2.4rem; } }
      @media screen and (min-width: 992px) {
        .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane img {
          max-width: 79%; } }
      @media screen and (min-width: 1200px) {
        .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane img {
          max-width: 98%; } }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane p {
        padding-top: 1rem;
        margin: 0; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane p:first-child {
        text-align: center; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
        padding: 2rem;
        background: #fff; }
        @media screen and (min-width: 992px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
            padding: 44px 30px; } }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
            padding: 18px 30px; } }
        .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div:hover {
          outline: 3px solid #efefef; }
  .panel-student-services-home #student-services-home-top #top-2col-right {
    padding-left: 10px;
    padding-right: 10px; }
    .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane {
      margin: 0;
      color: #555; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane:first-child {
        margin-top: 3.8rem;
        margin-bottom: 2rem; }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane:first-child {
            margin-top: 4rem; } }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div {
        padding: 2rem;
        background: #fff; }
        @media screen and (min-width: 768px) {
          .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div {
            padding: 1rem 3rem; } }
        .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div:hover {
          outline: 3px solid #efefef; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane img {
        max-width: 98%; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane p {
        padding-top: 1rem;
        margin: 0; }
    .panel-student-services-home #student-services-home-top #top-2col-right .panels-ipe-sort-container div.panels-ipe-portlet-wrapper:nth-child(2) {
      margin-top: -2rem; }

.panel-student-services-home #student-services-home-bottom .panel-pane {
  position: relative;
  background: #ddd;
  width: 100%;
  height: auto;
  min-height: 45rem;
  z-index: 1;
  opacity: 1;
  overflow: hidden;
  background-image: url("/sites/default/files/styles/panopoly_image_original/public/student_life_bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 40%, 0%;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  padding-bottom: 2rem;
  color: #555; }
  .panel-student-services-home #student-services-home-bottom .panel-pane:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* FF3.6+ */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.1)));
    /* Chrome,Safari4+ */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* Chrome10+,Safari5.1+ */
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* Opera 11.10+ */
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* IE10+ */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType='0');
    /* IE6-9 */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', gradientType='0')"; }
  .panel-student-services-home #student-services-home-bottom .panel-pane p {
    color: #fff;
    text-shadow: 1px 1px 2px #000; }
  .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane {
    padding: 0 2rem; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane {
        float: right;
        width: 50%;
        margin-right: 10%; } }
    @media screen and (min-width: 992px) {
      .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane {
        width: 35%;
        margin-right: 12%; } }
    .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane h3 {
      font-size: 4rem;
      margin-top: 4rem; }
    .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane .blue-link {
      margin-bottom: 0.6rem;
      max-width: 100%;
      opacity: 0.84;
      filter: alpha(opacity=84); }
      .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane .blue-link a {
        background-color: #e7751d;
        list-style-type: none;
        border: 0.3rem solid #fff;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        border-radius: 1.2rem;
        color: #fff;
        text-align: center;
        display: block;
        padding: 0.6rem 0; }
        @media screen and (min-width: 992px) {
          .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane .blue-link a {
            border: 0.3rem solid #fff;
            max-width: 98%; } }
        .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane .blue-link a:hover {
          background-color: #b31b1b;
          text-decoration: none; }
        .panel-student-services-home #student-services-home-bottom .panel-pane .fieldable-panels-pane .blue-link a span {
          font-size: 1.9rem;
          font-style: normal; }

.panel-student-services-home #student-services-home-footer .container {
  padding: 0; }

.panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row,
.panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row,
.panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row,
.panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row {
  clear: both; }
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-date,
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-date,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-date,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-date {
    float: left;
    margin: 0.2rem 1rem 0.6rem 0;
    background: #eeeeee none repeat scroll 0% 0%;
    color: #555555;
    text-align: center;
    display: block;
    max-width: 7.4rem;
    padding: 0.4rem 0 0 0;
    border-color: #ddd;
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 1px;
    width: 24%; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-date,
      .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-date,
      .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-date,
      .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-date {
        width: 18%; } }
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-date .date-display-single,
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-date .date-display-single,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-date .date-display-single,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-date .date-display-single {
      display: block;
      font-family: "1898Sans-Regular",sans-serif;
      font-size: 1.9rem;
      line-height: 0.8;
      margin-top: 1.2rem;
      margin-bottom: 0.7rem; }
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-date .date-display-range,
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-date .date-display-range,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-date .date-display-range,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-date .date-display-range {
      display: block;
      font-family: "1898Sans-Regular",sans-serif;
      margin-top: 1.7rem;
      line-height: 0.8;
      letter-spacing: -0.05rem;
      margin-top: 1.4rem;
      margin-bottom: 1rem; }
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-date p,
    .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-date p,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-date p,
    .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-date p {
      margin: 0; }
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .views-row .event-title,
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .views-row .event-title,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .views-row .event-title,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .views-row .event-title {
    font-size: 1.6rem; }

.panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .view-footer,
.panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .view-footer,
.panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .view-footer,
.panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .view-footer {
  clear: both;
  margin: 3.4rem 0 1rem 0; }
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .view-footer .btn,
  .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .view-footer .btn,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .view-footer .btn,
  .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .view-footer .btn {
    font-size: 1.3rem; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_1 .view-footer .btn,
      .panel-student-services-home #student-services-home-footer #footer-2col-left .view-display-id-panel_pane_2 .view-footer .btn,
      .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_1 .view-footer .btn,
      .panel-student-services-home #student-services-home-footer #footer-2col-right .view-display-id-panel_pane_2 .view-footer .btn {
        font-size: 1.5rem; } }

.panel-student-services-home #student-services-home-footer #footer-2col-left .panel-pane {
  margin: 3rem 0; }
  @media screen and (min-width: 992px) {
    .panel-student-services-home #student-services-home-footer #footer-2col-left .panel-pane {
      padding-right: 3rem; } }
  .panel-student-services-home #student-services-home-footer #footer-2col-left .panel-pane .pane-title {
    margin: 0 0 1rem 0; }

.panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] {
  padding-top: 0.4rem;
  padding-top: 0.4rem; }
  .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] .select2,
  .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] select {
    width: 25rem !important; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] .select2,
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] select {
        width: 35rem !important; } }
    @media screen and (min-width: 992px) {
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] .select2,
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] select {
        width: 40rem !important; } }
    @media screen and (min-width: 1200px) {
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] .select2,
      .panel-student-services-home #student-services-home-footer #footer-2col-left div[id^="edit-tid-"] select {
        width: 45rem !important; } }

.panel-student-services-home #student-services-home-footer #footer-2col-right {
  margin: 3rem 0; }
  @media screen and (min-width: 992px) {
    .panel-student-services-home #student-services-home-footer #footer-2col-right {
      border-left: 1px solid #ddd;
      padding-left: 3rem; } }
  .panel-student-services-home #student-services-home-footer #footer-2col-right .panel-pane .pane-title {
    margin: 0rem 0 1.2rem 0; }

.panel-student-services-home form .views-exposed-widget .ajax-progress-throbber {
  margin-top: 1.2rem; }

.panel-hero-3col #main-content .panel-pane table tbody {
  border: 1px solid #ddd; }

.panel-hero-3col #main-content .panel-pane table tr {
  border-bottom: 1px solid #ddd; }

.panel-hero-3col #main-content .panel-pane table tr:nth-child(even) {
  background: #fefefe; }

.panel-hero-3col #main-content .panel-pane table tr:nth-child(odd) {
  background: #efefef; }

.panel-hero-3col #main-content .panel-pane table tr:first-child {
  background: #ccc; }

.panel-hero-3col #main-content .panel-pane table td {
  padding: 0.4rem 0.6rem; }

.panel-hero-3col #main-content .ui-tabs {
  width: 100%;
  display: inline-block;
  font-family: "Open Sans",sans-serif;
  font-size: 15px;
  margin-top: -5px; }
  .panel-hero-3col #main-content .ui-tabs .item-list > ul {
    padding: 0px;
    margin: 1rem 0;
    border-radius: 0px !important;
    border-bottom: 1px solid #ddd !important; }
    .panel-hero-3col #main-content .ui-tabs .item-list > ul:after {
      display: block;
      clear: both;
      content: ''; }
    .panel-hero-3col #main-content .ui-tabs .item-list > ul li {
      float: left;
      list-style: outside none none;
      padding: 0px; }
    .panel-hero-3col #main-content .ui-tabs .item-list > ul a {
      display: inline-block;
      border-radius: 5px 5px 0px 0px;
      color: #CF4520;
      border-color: #ddd !important;
      border-width: 1px 1px 0 1px !important;
      border-style: solid !important;
      font-size: 10px;
      padding: 5px 5px;
      -webkit-transition: all linear 0.15s;
      -moz-transition: all linear 0.15s;
      -ms-transition: all linear 0.15s;
      -o-transition: all linear 0.15s;
      transition: all linear 0.15s; }
      @media screen and (min-width: 768px) {
        .panel-hero-3col #main-content .ui-tabs .item-list > ul a {
          font-size: 15px;
          padding: .5em 1em; } }
      .panel-hero-3col #main-content .ui-tabs .item-list > ul a:hover {
        border-color: #CF4520 !important;
        -webkit-transition: all linear 0.25s;
        -moz-transition: all linear 0.25s;
        -ms-transition: all linear 0.25s;
        -o-transition: all linear 0.25s;
        transition: all linear 0.25s;
        -webkit-box-shadow: 0 1px 4px #cf4520;
        -moz-box-shadow: 0 1px 4px #cf4520;
        box-shadow: 0 1px 4px #cf4520;
        text-decoration: none; }
    .panel-hero-3col #main-content .ui-tabs .item-list > ul li.ui-tabs-active {
      background: #fff; }
      .panel-hero-3col #main-content .ui-tabs .item-list > ul li.ui-tabs-active a {
        color: #fff;
        background: #CF4520; }
    .panel-hero-3col #main-content .ui-tabs .item-list > ul li.ui-state-default {
      background: #FFF; }
  .panel-hero-3col #main-content .ui-tabs .ui-widget-header {
    background: #FFF !important;
    font-family: inherit !important;
    font-size: inherit !important; }
  .panel-hero-3col #main-content .ui-tabs .ui-widget-content a {
    color: #CF4520; }
    .panel-hero-3col #main-content .ui-tabs .ui-widget-content a:hover {
      color: #E87722; }
  .panel-hero-3col #main-content .ui-tabs .ui-tabs-panel {
    padding: 1.2rem 0 !important; }
  .panel-hero-3col #main-content .ui-tabs .view-content .views-field table {
    font-size: 1.2rem; }
    @media screen and (min-width: 1200px) {
      .panel-hero-3col #main-content .ui-tabs .view-content .views-field table {
        font-size: 1.3rem; } }
    .panel-hero-3col #main-content .ui-tabs .view-content .views-field table tbody {
      border: 1px solid #ddd; }
    .panel-hero-3col #main-content .ui-tabs .view-content .views-field table tr:first-child {
      background: #cfcfcf; }
      .panel-hero-3col #main-content .ui-tabs .view-content .views-field table tr:first-child td:not(:last-child) {
        border-right: 1px solid #eee; }
    .panel-hero-3col #main-content .ui-tabs .view-content .views-field table tr:not(:first-child) td:nth-child(even) {
      background: #fff; }
    .panel-hero-3col #main-content .ui-tabs .view-content .views-field table tr:not(:first-child) td:nth-child(odd) {
      background: #efefef; }
    .panel-hero-3col #main-content .ui-tabs .view-content .views-field table td {
      padding: 0.2rem 0.4rem; }
      @media screen and (min-width: 1200px) {
        .panel-hero-3col #main-content .ui-tabs .view-content .views-field table td {
          padding: 0.4rem 0.6rem; } }

.panel-hero-3col .pane-events-panel-pane-4 .views-row,
.panel-hero-3col .pane-events-panel-pane-5 .views-row,
.panel-hero-3col .pane-events-panel-pane-6 .views-row,
.panel-hero-3col .pane-events-panel-pane-7 .views-row {
  margin-bottom: 1.5rem; }
  .panel-hero-3col .pane-events-panel-pane-4 .views-row .event-item,
  .panel-hero-3col .pane-events-panel-pane-5 .views-row .event-item,
  .panel-hero-3col .pane-events-panel-pane-6 .views-row .event-item,
  .panel-hero-3col .pane-events-panel-pane-7 .views-row .event-item {
    border-bottom: 1px solid #ddd; }
    .panel-hero-3col .pane-events-panel-pane-4 .views-row .event-item .event-date,
    .panel-hero-3col .pane-events-panel-pane-5 .views-row .event-item .event-date,
    .panel-hero-3col .pane-events-panel-pane-6 .views-row .event-item .event-date,
    .panel-hero-3col .pane-events-panel-pane-7 .views-row .event-item .event-date {
      font-size: 1.4rem; }
    .panel-hero-3col .pane-events-panel-pane-4 .views-row .event-item .event-title,
    .panel-hero-3col .pane-events-panel-pane-5 .views-row .event-item .event-title,
    .panel-hero-3col .pane-events-panel-pane-6 .views-row .event-item .event-title,
    .panel-hero-3col .pane-events-panel-pane-7 .views-row .event-item .event-title {
      font-size: 1.6rem;
      font-weight: 800; }
      .panel-hero-3col .pane-events-panel-pane-4 .views-row .event-item .event-title + div,
      .panel-hero-3col .pane-events-panel-pane-5 .views-row .event-item .event-title + div,
      .panel-hero-3col .pane-events-panel-pane-6 .views-row .event-item .event-title + div,
      .panel-hero-3col .pane-events-panel-pane-7 .views-row .event-item .event-title + div {
        line-height: 1; }

.panel-hero-3col .pane-events-panel-pane-4 .select2,
.panel-hero-3col .pane-events-panel-pane-5 .select2 {
  width: 22rem !important;
  margin-bottom: 2rem; }

.panel-hero-3col .pane-profiles-panel-pane-2 .view-content h3 {
  padding-bottom: 1rem; }

.panel-hero-3col .pane-profiles-panel-pane-2 .view-content div.views-row {
  border-bottom: 1px solid #efefef;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  display: inline-block;
  width: 100%;
  clear: both; }
  @media screen and (min-width: 992px) {
    .panel-hero-3col .pane-profiles-panel-pane-2 .view-content div.views-row .profile-list-image {
      margin-right: 2rem;
      float: left; } }
  .panel-hero-3col .pane-profiles-panel-pane-2 .view-content div.views-row .profile-list-image .wcmc-profile-table {
    height: auto;
    width: auto;
    margin-bottom: 10px;
    max-height: 15rem; }
  .panel-hero-3col .pane-profiles-panel-pane-2 .view-content div.views-row .views-field-nothing .field-content {
    font-weight: 800; }

.panel-hero-3col .pane-news-highlights-panel-pane-1 .view-content div.views-row {
  border-bottom: 1px solid #efefef;
  padding-bottom: 1.8rem; }

.panel-hero-3col .pane-panopoly-taxonomy-taxonomy-content .view-content div.views-row {
  border-bottom: 1px solid #efefef; }

.panel-hero-3col .pane-panopoly-taxonomy-taxonomy-content .view-content article h2 {
  font-size: 2.2rem; }

.panel-hero-3col .pane-panopoly-taxonomy-taxonomy-content .view-content article ul {
  padding-top: 1rem; }

.panel-hero-3col form .views-exposed-widget .ajax-progress-throbber {
  margin-top: 2rem; }

.information-column .panel-pane {
  text-align: center;
  margin-bottom: 2rem; }
  .information-column .panel-pane h3.pane-title {
    margin: 0;
    margin-bottom: 1.5rem; }
  .information-column .panel-pane .fieldable-panels-pane ul {
    padding-left: 1.2rem;
    font-size: 1.4rem;
    text-align: left; }

.information-column .wcmc-btn-link {
  text-align: center;
  border-bottom: 1px solid #efefef;
  margin-bottom: 2rem;
  padding-bottom: 2rem; }

.information-column .pane-wcmc-contact-pane .contact-info h3.pane-title {
  margin: 0; }

.information-column .pane-map-this-pane {
  width: 100%;
  margin: 3rem auto 3rem auto; }
  .information-column .pane-map-this-pane .map-this-location {
    text-align: center; }
    .information-column .pane-map-this-pane .map-this-location a.external-link:after {
      display: none; }
    .information-column .pane-map-this-pane .map-this-location a.external-link:hover {
      color: #e87722;
      text-decoration: none; }
    .information-column .pane-map-this-pane .map-this-location a.external-link .wcm-cta__map-this-sidebar {
      -webkit-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%); }
      .information-column .pane-map-this-pane .map-this-location a.external-link .wcm-cta__map-this-sidebar .cta-icon {
        margin-left: -1.4rem !important;
        font-size: 3.8rem;
        color: #b31b1b;
        fill: #e97623; }
      .information-column .pane-map-this-pane .map-this-location a.external-link .wcm-cta__map-this-sidebar > span {
        position: relative;
        bottom: 12px;
        left: 8px;
        color: #b31b1b;
        font-size: 1.5rem; }

footer .footer-contact .footer-contact__site-name a {
  font-size: 15px; }
  @media screen and (min-width: 992px) {
    footer .footer-contact .footer-contact__site-name a {
      font-size: 18px; } }

footer .footer-site .footer-address {
  border-right: none; }
  @media screen and (min-width: 992px) {
    footer .footer-site .footer-address {
      width: 20% !important; } }

footer .footer-site .footer-nav {
  margin-bottom: 2rem;
  border-left: 1px solid #DDD; }
  @media screen and (min-width: 992px) {
    footer .footer-site .footer-nav {
      width: 78% !important; } }
  footer .footer-site .footer-nav .block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    -ms-column-gap: 2rem;
    -o-column-gap: 2rem;
    column-gap: 2rem; }
    footer .footer-site .footer-nav .block ul.menu {
      width: 85%; }
      footer .footer-site .footer-nav .block ul.menu li:nth-child(2) {
        display: inline-block; }
